<template>
 <!-- <a href="https://t.ajrkm3.com/339978/6224/0?bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN" target="_blank">
    <img src="https://www.imglnkx.com/9112/IMG_7566-White-tank-top-set.jpeg" alt="Promotional Image" width="100%" />
  </a> -->
  <div v-if="shortsActive && videos.length > 0" class="shorts-container">

    <div v-if="isPhoneSized" class="video-title">
      <img
        src="@/assets/fapshort2.png"
        alt="Image 1"
        class="animated-image image1"
      />
    </div>
    <button v-if="!isPhoneSized" @click="exitShortsView" class="exit-button">
      &times;
    </button>
    <!-- <button
      v-if="isPhoneSized"
      @click="toggleMenu"
      :class="{ spin: isMenuOpen }"
      class="burger-menu"
    >
      &#9776;
    </button> -->
  <button @click="showPremium" class="new-image-button">
      <img src="@/assets/ghicon.png" alt="New Image Button" class="new-image-icon"/>
      <!-- <video autoplay loop muted playsinline class="new-circular-video">
        <source :src="ghRandomVideoSource" type="video/mp4" />
        Your  does not support the video tag.
      </video> -->
        <img src="@/assets/1.gif" alt="Animated Button" class="new-circular-video" /> 

    </button>


    <div
      ref="videoListContainer"
      class="video-list-container"
      @scroll="handleScroll"
    >
 <div
      class="video-wrapper"
      @wheel="handleScroll"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <video
        ref="mainVideoPlayer"
        class="short-video"
        :class="{ 'fade-in': showPlaceholder }"
        :src="currentVideo.url"
        loop
        autoplay
        :muted="isMuted"
        preload="none"
        @timeupdate="updateProgress"
        @loadedmetadata="updateVideoDuration"
        @canplay="onVideoReady"
        @error="handleVideoError"
      >
        Your browser does not support the video tag.
      </video>
  <!-- Insert Placeholder After Every 8 Videos -->


           <ProfileSection 
          :username="currentVideo.user.username"
          :profileImageUrl="currentVideo.user.profile_image_url" 
          :description="currentVideo.user.description" 
          :onlyFansUrl="currentVideo.user.onlyfans_url" 
          
        />

        <div class="play-button" @click="togglePlay">
        <img
          src="@/assets/icons8-play-64.png"
          v-if="showPlayButton"
          alt="Play"
          class="play-icon"
        />
      </div>
      <input
        type="range"
        class="progress-bar"
        min="0"
        :max="videoDuration"
        step="0.1"
        v-model="currentTime"
        @input="seekVideo"
      />
          <!-- <div
    v-if="(index + 1) % 8 === 0"
    class="video-placeholder"
  >
    <div class="placeholder-icon">
    </div>

    <div class="placeholder-text">
      <h3>Are you a content creator?</h3>
      <p>
        Become part of our content creator community. Upload your videos to get
        millions of likes, followers, and clicks to your sites (OnlyFans, Fansly, and others).
      </p>
      <button @click="showPremium"  class="join-button">Become a partner today</button>
    </div>
  </div> -->
          
      </div>
    </div>

    <div v-if="isPhoneSized" class="additional-buttons">
      <!-- <button class="channel-button" @click="goToChannel">
        <img src="@/assets/logo.png" alt="Channel" />
      </button> -->

      <button
  class="channel-button black-outline"
        @click="openLink('https://t.antj.link/339978/9267/0?bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN')"
      >
                <img src="@/assets/bdsm.gif" alt="Animated Button" class="new-circular-gif" /> 

      </button>
      <button
        class="channel-button"
        @click="openLink('https://t.ajrkm3.com/339978/6224/0?bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN')"
      >
        <!-- <video autoplay loop muted playsinline class="channel-video">
          <source :src="randomVideoSrc" type="video/mp4" />
          Your bro does not support the video tag.
        </video> -->
                <img src="@/assets/2.gif" alt="Animated Button" class="new-circular-gif" />

      </button>
      <button class="right-button mute-button" @click="toggleMute">
        <img
          :src="
            isMuted
              ? require('@/assets/icons8-mute-50.png')
              : require('@/assets/icons8-sound-speaker-50.png')
          "
          alt="Mute"
          class="mute-icon"
        />
      </button>
      <button
        class="right-button heart-button"
        @click="likeVideo"
        :class="{ liked: isLiked }"
      >
        <img
          src="@/assets/icons8-heart-50.png"
          alt="Heart"
          class="heart-icon"
        />
        <!-- Add this line -->
      </button>
      <div class="likes-counter">{{ formattedLikes }}</div>
      <!-- <div class="likes-counter">{{ currentVideo.likes }}</div> -->

      <button
        class="right-button heart-button share-button"
        @click="shareVideo"
      >
        <img
          src="@/assets/icons8-share-48.png"
          alt="Share"
          class="heart-icon"
        />
      </button>

      <!-- <button class="right-button login-button" @click="showLoginForm = true">
    <img src="@/assets/icons8-account-49.png" alt="Login" class="login-icon" />
  </button> -->
      <!-- <button
        class="right-button heart-button comment-button"
        @click="commentVideo"
      >
        <img
          src="@/assets/icons8-speech-bubble-50.png"
          alt="Comment"
          class="heart-icon"
        />
      </button> -->
      <button
        class="right-button shuffle-button"
        :class="{ shuffleActive: isShuffling }"
        @click="shuffleVideos"
      >
        <img src="@/assets/shuffle-64.png" alt="Shuffle" class="shuffle-icon" />
      </button>

      <button
  class="right-button flag-button"
  @click="toggleReportForm"
>
  <font-awesome-icon :icon="['fas', isFlagged ? 'flag' : 'flag']" class="flag-icon" />

</button>
<button
  class="pd-button"
  @click="openLink('https://www.porndude.com')"
>
  <img src="@/assets/pdlogo.png" alt="Porndude Logo" class="pd-icon" />
</button>

    </div>
    <!-- Navigation buttons here -->
    <button
      @click="goToPreviousVideo"
      class="nav-button left"
      v-if="!isPhoneSized"
    >
      &lt;
    </button>
    <button
      @click="goToNextVideo"
      class="nav-button right"
      v-if="!isPhoneSized"
    >
      &gt;
    </button>
  </div>

  <div v-if="showReportForm" class="report-popup">
  <form @submit.prevent="sendReport">
    <h3>Report Video</h3>
    <label for="subject">Subject:</label>
    <input type="text" id="subject" v-model="reportSubject" required />

    <label for="description">Description:</label>
    <textarea id="description" v-model="reportDescription" required></textarea>

    <button type="submit" class="send-report-button">Send Report</button>
  </form>
  <button @click="toggleReportForm" class="close-popup-button">&times;</button>
</div>

  <div v-if="isMenuOpen" class="menu-overlay">
    <button @click="toggleMenu" class="close-menu-button">&times;</button>

    <div class="menu-list">
      <!-- <button @click="logIn">Log in / Sign up</button> -->
      <button @click="liveCam">Cams</button>
      <button @click="liveCam">Liked Videos</button>
      <button @click="pornGames">Games</button>
      <button @click="meetAndFuck">Meet</button>
      <button @click="myProfile">My Profile</button>
      <button @click="pornstars">stars</button>
      <button @click="studios">Studios</button>
      <button @click="actions">Actions</button>
      <button @click="tags">Tags</button>
      <button @click="contactUs">Contact Us</button>
      <button @click="contentRemoval">Content Removal</button>
      <button @click="termsAndConditions">Terms and Conditions</button>
      <button @click="privacyPolicy">Privacy Policy</button>
    </div>
  

  </div>

      <LoginForm v-if="showLoginForm" @submit-form="handleFormSubmit" @close="this.showLoginForm = false"  @login-success="handleLoginSuccess" />
    <PremiumPurchaseForm v-if="showPremiumForm" @close="showPremiumForm = false" />
    <div v-if="showPremiumContent" class="premium-content-wrapper">
      <VideoGridComponent 
        @close-premium="handleClosePremium"
 
        :premium="true" 
      />

    </div>

</template>


<script>
import { nextTick } from "vue";
import LoginForm from './LoginForm.vue'; // Import the LoginForm component
import PremiumPurchaseForm from './PremiumPurchaseForm.vue'; 
import VideoGridComponent from './VideoGridComponent.vue';
// import debounce from 'lodash/debounce';
import ProfileSection from './ProfileSection.vue';

export default {
  name: "ShortsViewComponent",
  props: {
    videos: {
    type: Array,
    required: true, // Ensure it's a required prop to guarantee it gets passed
    default: () => [], // Default to an empty array to avoid undefined issues
  },
    shortsActive: Boolean,
    videoId: String,
    isLoggedIn: Boolean, 
    //currentVideoIndex: Number, // Ensure this is passed as a prop
  },
  components: {
    ProfileSection,
    LoginForm, 
    PremiumPurchaseForm, 
    VideoGridComponent,
  },
  
  data() {
    return {
      currentVideoIndex: 0,
      isLiked: false,
      isMenuOpen: false,
      showPlayButton: true,
      isMuted: false,
      currentTime: 0,
      videoDuration: 0,
      startY: 0,
      endY: 0,
      copySuccess: "",
      showToast: false,
      isShuffling: false,
      randomVideoSrc: "",
      ghRandomVideoSource: "",
      showLoginForm: false, 
      showPremiumForm: false,
      showPremiumContent: false,
      userInteracted: false,
      isVideoVisible: [],
      isFlagged: false,
    showReportForm: false,
    reportSubject: '',
    reportDescription: '',
    canLoadMore: true, 
     isLoadingMore: false,
      showPlaceholder: true,
        lastScrollTime: 0, 
    scrollDelay: 300,
    abortControllers: {}, 
    };
  },
  created() {
    this.setRandomVideoSrc();
    this.setGhRandomVideoSource();
    this.checkLoginStatus(); // Add this line
  },
  computed: {
    formattedLikes() {
      const likes = this.currentVideo.likes;
      if (likes >= 1000) {
        return (likes / 1000).toFixed(1) + "K";
      }
      return likes;
    },
    isPhoneSized() {
      return true;
      // return window.innerWidth <= 768;
    },
    currentVideo() {
      console.log(
        "Recalculating current video for index:",
        this.currentVideoIndex
      );

      if (
        !this.videos ||
        this.videos.length === 0 ||
        this.currentVideoIndex < 0 ||
        this.currentVideoIndex >= this.videos.length
      ) {
        console.error("Invalid currentVideoIndex or empty videos array");
        return {}; // Return an empty object to safely handle undefined properties
      }
      return this.videos[this.currentVideoIndex];
    },
  },
emits: ["exit-shorts", "load-more-videos", "login-success", "update-login-status", 'submit-form'], // Correct naming
mounted() {
      this.setupIntersectionObserver();

   this.loadLiveCamWidget();
   const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1, // Adjust the threshold to 0.6 or any value that makes sense
  };

  // Add event listeners to detect user interaction
  window.addEventListener('click', () => this.handleUserInteraction());
  window.addEventListener('scroll',() => this.handleUserInteraction());
  window.addEventListener('keydown',() => this.handleUserInteraction());
  
  // Add touch event listeners for mobile devices
  window.addEventListener('touchstart',() => this.handleUserInteraction());
  window.addEventListener('touchend',() => this.handleUserInteraction());
  
  console.log("Video ID received:", this.videoId);
  this.setCurrentVideoIndex(); // Set the current video index
  this.checkIfLiked(); // Check if the current video is liked

      console.log('Available refs:', this.$refs);
this.observer = new IntersectionObserver(this.handleVideoVisibility, options);

  // Observe each video item in the list
  //  this.$refs.videoListContainer.querySelector('.video-item').forEach((item) => {
  //   this.observer.observe(item);
  // });

  this.$nextTick(() => {
        console.log('Available refs:', this.$refs);

          console.log('Mounted refs:', this.$refs);
    this.videos.forEach((_, index) => {
      const ref = this.$refs[`videoPlayer-${index}`];
      console.log(`Video player ref for index ${index}:`, ref);
    });

    const videoPlayer = this.$refs[`videoPlayer-${this.currentVideoIndex}`]; // Reference the first video directly

    if (videoPlayer && videoPlayer[0]) { // Updated check to avoid undefined errors
      videoPlayer[0].autoplay = false; // Disable autoplay initially
      videoPlayer[0].muted = this.isMuted; // Ensure the video is muted if required

      // Initialize video duration once metadata is loaded
      videoPlayer[0].addEventListener("loadedmetadata", () => {
        this.updateVideoDuration(this.currentVideoIndex);
      });
    } else {
      console.error("Video player reference not found.");
    }
  });

  // Ensure all other videos are paused initially
  this.pauseAllVideos();
},



beforeUnmount() {
  window.removeEventListener('click', this.handleUserInteraction);  // Remove event listeners when the component is destroyed
  window.removeEventListener('scroll', this.handleUserInteraction);
  window.removeEventListener('keydown', this.handleUserInteraction);
  window.removeEventListener('touchstart', this.handleUserInteraction);
  window.removeEventListener('touchend', this.handleUserInteraction);
},
  watch: {
    currentVideo(newVideo, oldVideo) {
      if (newVideo.id !== oldVideo.id) {
        this.checkIfLiked(); // Check if the new video is liked
      }
    },
    videos(newVideos) {
    if (newVideos && newVideos.length > 0) {
      // console.log('Videos fetched:', newVideos);
      this.preloadNextVideos(this.currentVideoIndex); // Preload videos after fetching
    }
  },
  },
  methods: {
     setupIntersectionObserver() {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Adjust as needed to trigger when 50% of the video is in view
        };

        // Create the observer
        this.observer = new IntersectionObserver(this.handleIntersection, options);

        // Observe each video element
        this.videos.forEach((_, index) => {
            const videoElement = this.$refs[`videoPlayer-${index}`];
            if (videoElement) {
                this.observer.observe(videoElement);
            }
        });
    },
    onVideoReady() {
    // Hide the placeholder and show the video with fade-in effect
    this.showPlaceholder = false;
  },
preloadNextVideos(currentIndex) {
  const preloadCount = 2; // Number of videos to preload ahead of the current one

  // Initialize the preloadedVideos array if it doesn't exist
  if (!this.preloadedVideos) {
    this.preloadedVideos = [];
  }

  // Preload videos starting from the current index up to preloadCount
  for (let i = currentIndex + 1; i <= currentIndex + preloadCount; i++) { // Adjust to start from the next video
    if (i >= 0 && i < this.videos.length) { // Ensure index is within bounds
      // Check if the video has already been preloaded
      if (!this.preloadedVideos.includes(this.videos[i].id)) {
        const videoElement = document.createElement('video');
        videoElement.src = this.videos[i].url;
        videoElement.preload = 'metadata'; // Preload video metadata
        videoElement.load(); // Start preloading the video

        // Mark the video as preloaded by storing its ID
        this.preloadedVideos.push(this.videos[i].id);
      }
    }
  }
},






    toggleReportForm() {
    this.isFlagged = !this.isFlagged;
    this.showReportForm = !this.showReportForm;
  },
  sendReport() {
    // Handle the form submission, e.g., send the report to a server
    console.log('Report sent:', this.reportSubject, this.reportDescription);
    this.showReportForm = false;
    this.reportSubject = '';
    this.reportDescription = '';
  },
   loadLiveCamWidget() {
    // Check if the widget script is already loaded to avoid duplication
    if (document.getElementById("live-cam-script")) {
        console.log("Live cam script already added.");
        return;
    }

    // Wait for the DOM to update and ensure the element exists
    this.$nextTick(() => {
        const widgetElement = document.getElementById("live-cam-widget");
        if (widgetElement) {
            const script = document.createElement("script");
            script.id = "live-cam-script";
            script.src = "https://www.cemiocw.com/script?landing_id=%7Boffer_url_id%7D&genders=f%2Cff&categories=anal%2Cbigass%2Cblowjob%2Cmilf&providersId=27&skin=0&containerAlignment=center&cols=0&rows=0&number=50&background=transparent&useFeed=1&animateFeed=1&smoothAnimation=1&ratio=1&verticalSpace=10px&horizontalSpace=10px&colorFilter=0&colorFilterStrength=0&AuxiliaryCSS=%0A&token=703927f0-6f9c-11ef-8f8a-19747794bedc";
            script.async = true;

            script.onload = () => {
                console.log("Live cam widget script loaded successfully.");
                this.initializeWidget();
            };

            script.onerror = (error) => {
                console.error("Error loading the live cam widget script:", error);
            };

            document.head.appendChild(script);
        } else {
            console.error("live-cam-widget element not found.");
        }
    });
},

initializeWidget() {
    const widgetElement = document.getElementById("live-cam-widget");
    if (widgetElement) {
        // Add additional initialization logic here if needed
        console.log("Widget initialized successfully.");
    } else {
        console.error("Widget element not found during initialization.");
    }
},


    handleVideoError() {
      console.error("Error loading video.");
    },
     handleFormSubmit(formData) {
      console.log('ParentComponent received form data:', formData);
      this.$emit('submit-form', formData); // Re-emit the event upwards
    },
    attachEventListeners(videoPlayer, index) {
    videoPlayer.addEventListener("timeupdate", () => this.updateProgress(index));
    videoPlayer.addEventListener("loadedmetadata", () => this.updateVideoDuration(index));
  },
  removeEventListeners(videoPlayer, index) {
    videoPlayer.removeEventListener("timeupdate", () => this.updateProgress(index));
    videoPlayer.removeEventListener("loadedmetadata", () => this.updateVideoDuration(index));
  },
handleIntersection(entries) {
  entries.forEach((entry) => {
    const videoElement = entry.target;
    const index = Number(videoElement.getAttribute('data-index'));

    if (entry.isIntersecting) {
      // Video is in view
      if (!videoElement.src) {
        // Abort any previous fetch for this video
        if (this.abortControllers[index]) {
          this.abortControllers[index].abort();
        }

        // Create a new AbortController for the new fetch
        const abortController = new AbortController();
        this.abortControllers[index] = abortController;

        // Fetch the video using the new AbortController
        fetch(this.videos[index].url, { signal: abortController.signal })
          .then((response) => response.blob())
          .then((blob) => {
            videoElement.src = URL.createObjectURL(blob);
            videoElement.load();
            videoElement.play();
          })
          .catch((err) => {
            if (err.name === 'AbortError') {
              console.log('Fetch aborted for video:', index);
            } else {
              console.error('Fetch error:', err);
            }
          });
      }
    } else {
      // Video is out of view
      videoElement.pause();
      videoElement.removeAttribute('src'); // Remove the source to stop loading
      videoElement.load(); // Reset the video element

      // Abort the fetch if it's still ongoing
      if (this.abortControllers[index]) {
        this.abortControllers[index].abort();
        delete this.abortControllers[index];
      }
    }
  });
},


  pauseAllVideosExceptCurrent() {
  // Loop through all videos and pause all except the current one
  this.videos.forEach((video, i) => {
    const videoPlayer = this.$refs[`videoPlayer-${i}`];
    if (videoPlayer && videoPlayer[0] && i !== this.currentVideoIndex) {
      videoPlayer[0].pause();
    }
  });
},
handleVideoVisibility(entries) {
  let newVideoIndex = this.currentVideoIndex; // Initialize with the current video index

  // Find the most visible video entry
  entries.forEach((entry) => {
    const index = Number(entry.target.getAttribute("data-index"));
    if (entry.isIntersecting) {
      newVideoIndex = index; // Set the new active video index
    }
  });

  // Update the active video only if it's changed
  if (newVideoIndex !== this.currentVideoIndex) {
    this.setActiveVideo(newVideoIndex);
  }
},

// Refactor `setActiveVideo` method for optimized video handling
   setActiveVideo(index) {
  if (this.currentVideoIndex === index) return;

  this.currentVideoIndex = index;

  const videoPlayer = this.$refs.mainVideoPlayer;

  // Use the preloaded video if it exists
  if (this.preloadedVideos[index]) {
    videoPlayer.src = this.preloadedVideos[index].src;
    delete this.preloadedVideos[index]; // Clean up the preloaded video reference
  } else {
    // Fallback: load the video directly if not preloaded
    videoPlayer.src = this.videos[index].url;
  }

  videoPlayer.load();
  videoPlayer.play();

  // Preload the next set of videos
  this.preloadNextVideos(index);
},




  handleUserInteraction() {
    if (!this.userInteracted) { // Ensure interaction handling only once
      this.userInteracted = true;
      console.log('User interacted, videos can play.');

      this.playCurrentVideo(); // Play the current video if first-time interaction

      // Remove event listeners once interaction is confirmed
      window.removeEventListener('click', this.handleUserInteraction);
      window.removeEventListener('scroll', this.handleUserInteraction);
      window.removeEventListener('keydown', this.handleUserInteraction);
      window.removeEventListener('touchstart', this.handleUserInteraction);
      window.removeEventListener('touchend', this.handleUserInteraction);
    }
  },


    allowAutoplay() {
    this.userInteracted = true; // Set flag to true upon interaction
    console.log('User interacted, videos can play.');
    // Remove event listeners once the user has interacted
    window.removeEventListener('click', this.allowAutoplay);
    window.removeEventListener('touchstart', this.allowAutoplay);
  },
    handleClosePremium() {
       this.showPremiumContent = false;
    },
     handleLoginSuccess(premiumStatus) {
      this.$emit("update-login-status", true);
      this.showLoginForm = false;
      if (premiumStatus) {
        this.showPremiumContent = true;
      } else {
        this.showPremiumForm = true;
      }
    },
   async checkLoginStatus() {
    const token = localStorage.getItem('authToken');
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    const premiumStatus = localStorage.getItem('premium') === 'true'; // Get premium status from localStorage
    const sessionDuration = 30 * 60 * 1000;

    if (token && loginTimestamp && Date.now() - parseInt(loginTimestamp) <= sessionDuration) {
      try {
        // Make an API call to verify the token
        const response = await fetch('/api/check_token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          this.$emit('update-login-status', data.isValid); // Emit the event with the login status
          if (data.isValid && premiumStatus) {
            this.showPremiumContent = true; // Show premium content if the token is valid and the user is premium
          }
        } else {
          this.$emit('update-login-status', false); // Emit the event with false
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        this.$emit('update-login-status', false); // Emit the event with false
      }
    } else {
      this.$emit('update-login-status', false); // Emit the event with false
    }
  },
    showPremium() {
  if (this.isLoggedIn) {
    if (this.showPremiumContent) {
      this.showPremiumContent = false; // Close the video grid if it's already open
    } else {
      const premiumStatus = localStorage.getItem('premium') === 'true';
      if (premiumStatus) {
        this.showPremiumContent = true; // Show the video grid
      } else {
        this.showPremiumForm = true; // Show the premium purchase form
      }
    }
  } else {
    this.showLoginForm = true; // Show the login form
  }
},
    logIn() {
      this.showLoginForm = true;
    // Handle the login logic here
    console.log("Login button clicked");
    // You can navigate to the login page or open a login modal here
  },
    OnSwipe(event) {
      console.log("swipe occuring");
      if (event.direction === "up") {
        console.log("swiped up");
        this.goToNextVideo();
      } else if (event.direction === "down") {
        console.log("swiped down");
        this.goToPreviousVideo();
      }
    },
    setRandomVideoSrc() {
      const randomNumber = Math.floor(Math.random() * 9) + 1;
      this.randomVideoSrc = require(`@/assets/${randomNumber}.mp4`);
    },
    setGhRandomVideoSource(){
   const randomNumber = Math.floor(Math.random() * 20) + 1;
      this.ghRandomVideoSource = require(`@/assets/mouth/ghmouth${randomNumber}.mp4`);
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    checkIfLiked() {
      const likedVideos = JSON.parse(localStorage.getItem("likedVideos")) || [];
      this.isLiked = likedVideos.includes(this.currentVideo.id);
    },
    async likeVideo() {
      const likedVideos = JSON.parse(localStorage.getItem("likedVideos")) || [];

      if (this.isLiked) {
        // Unlike the video
        this.isLiked = false;
        const index = likedVideos.indexOf(this.currentVideo.id);
        if (index > -1) {
          likedVideos.splice(index, 1);
        }
        this.currentVideo.likes -= 1;
      } else {
        // Like the video
        this.isLiked = true;
        likedVideos.push(this.currentVideo.id);
        this.currentVideo.likes += 1;
      }

      localStorage.setItem("likedVideos", JSON.stringify(likedVideos));
      await this.updateLikes(this.currentVideo.id);
    },
    async updateLikes(videoId) {
      try {
        const response = await fetch(
          `https://www.fapshort.com/videos/${videoId}/update_likes`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Likes updated:", data);
      } catch (error) {
        console.error("Error updating likes:", error);
      }
    },
    shuffleVideos() {
      console.log("Shuffle button clicked");
      this.isShuffling = !this.isShuffling; // Toggle isShuffling state
      console.log("isShuffling:", this.isShuffling);
      if (this.isShuffling) {
        for (let i = this.videos.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [this.videos[i], this.videos[j]] = [this.videos[j], this.videos[i]];
        }
        this.resetVideoState();
        this.playCurrentVideo();
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

togglePlay() {
      const videoPlayer = this.$refs.mainVideoPlayer;
      if (videoPlayer.paused) {
        videoPlayer.play();
        this.showPlayButton = false;
      } else {
        videoPlayer.pause();
        this.showPlayButton = true;
      }
    },










 // Debounce by 200ms to prevent rapid toggling

    toggleMute() {
      const videoPlayer = this.$refs[`videoPlayer-${this.currentVideoIndex}`];
      if (videoPlayer && videoPlayer.length) {
        this.isMuted = !this.isMuted;
        videoPlayer[0].muted = this.isMuted;
      } else {
        console.error("Video player reference not found.");
      }
    },

   handleScroll(event) {
    const currentTime = new Date().getTime();

    // If scrolling too rapidly, ignore the event
    if (currentTime - this.lastScrollTime < this.scrollDelay) {
      return;
    }

    const videoListContainer = this.$refs.videoListContainer;
    const scrollTop = videoListContainer.scrollTop;
    const containerHeight = videoListContainer.clientHeight;
    const scrollHeight = videoListContainer.scrollHeight;

    // Detect scroll direction and navigate to next or previous video
    if (event.deltaY > 0) {
      this.goToNextVideo();
    } else if (event.deltaY < 0) {
      this.goToPreviousVideo();
    }

    // Check if near the bottom of the list for loading more videos
    if (scrollTop + containerHeight >= scrollHeight - 100 && !this.isLoadingMore) {
      this.isLoadingMore = true; // Set loading flag
      this.loadMoreVids().finally(() => {
        this.isLoadingMore = false; // Reset loading flag once done
      });
    }

    // Update the last scroll time
    this.lastScrollTime = currentTime;
  },


  updateVideoDuration() {
      const videoPlayer = this.$refs.mainVideoPlayer;
      this.videoDuration = videoPlayer.duration;
    },
     updateProgress() {
      const videoPlayer = this.$refs.mainVideoPlayer;
      this.currentTime = videoPlayer.currentTime;
    },
   seekVideo(event) {
      const videoPlayer = this.$refs.mainVideoPlayer;
      videoPlayer.currentTime = event.target.value;
    },
    resetVideoState() {
      this.isLiked = false;
      this.showPlayButton = false;
      this.currentTime = 0;
      this.videoDuration = 0;
      nextTick(() => {
        const videoPlayer = this.$refs[`videoPlayer-${this.currentVideoIndex}`];
        if (videoPlayer && videoPlayer.length) {
          videoPlayer[0].muted = this.isMuted;
          videoPlayer[0].currentTime = this.currentTime;
          this.updateVideoDuration(this.currentVideoIndex);
        }
      });
    },

    // likeVideo() {
    //   this.isLiked = !this.isLiked;

    //   console.log("Liked video:", this.currentVideo);
    // },
    shareVideo() {
      const currentUrl = window.location.href;
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          this.copySuccess = "URL copied to clipboard!";
          this.showToastNotification();
        })
        .catch((err) => {
          this.copySuccess = "Failed to copy URL";
          console.error("Could not copy text: ", err);
        });

      console.log("Shared video:", this.currentVideo);
    },
    showToastNotification() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000); // Toast visible for 3 seconds
    },
    commentVideo() {
      console.log("Commented on video:", this.currentVideo);
    },
     async loadMoreVids() {
    try {
      this.$emit('load-more-videos');
      await new Promise(resolve => setTimeout(resolve, 10000)); // Simulate network delay
      console.log('Videos loaded successfully!');
    } catch (error) {
      console.error('Error loading more videos:', error);
    }
  },
    exitShortsView() {
      this.$emit("exit-shorts");
    },
      async goToNextVideo() {
    if (this.currentVideoIndex < this.videos.length - 1) {
      await this.pauseCurrentVideo(); // Pause current video and wait for it to finish
      this.currentVideoIndex++; // Move to the next video
          this.preloadNextVideos(this.currentVideoIndex); // Preload the next set of videos

      // this.preloadNextVideos(this.currentVideoIndex); // Preload the next video
      this.scrollAnimation(); // Trigger scroll animation
      await this.updateVideoSource(); // Load and autoplay the next video
    }
  },

  scrollAnimation() {
    const videoListContainer = this.$refs.videoListContainer;
    videoListContainer.classList.add('scroll-animation');
    setTimeout(() => {
      videoListContainer.classList.remove('scroll-animation');
    }, 300); // Duration of the animation
  },

  pauseCurrentVideo() {
    return new Promise((resolve) => {
      const videoPlayer = this.$refs.mainVideoPlayer;
      if (videoPlayer && !videoPlayer.paused) {
        videoPlayer.pause();
        videoPlayer.onpause = () => {
          resolve(); // Resolve the promise once the video is fully paused
        };
      } else {
        resolve(); // Resolve immediately if the video is already paused
      }
    });
  },


    pauseAllVideos() {
  this.videos.forEach((video, index) => {
    const videoPlayer = this.$refs[`videoPlayer-${index}`];
    if (videoPlayer && videoPlayer.length && index !== this.currentVideoIndex) {
      videoPlayer[0].pause();
      videoPlayer[0].load(); // Ensure resources are freed up
    }
  });
},


 playCurrentVideo() {
    const videoPlayer = this.$refs.mainVideoPlayer;
    if (videoPlayer) {
      videoPlayer.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    }
  },


 handleTouchStart(event) {
      this.startY = event.touches[0].clientY;
    },
      handleTouchMove(event) {
      const currentY = event.touches[0].clientY;
      const deltaY = this.startY - currentY;

      if (Math.abs(deltaY) > 50) { // Adjust the threshold for sensitivity
        if (deltaY > 0) {
          this.goToNextVideo();
        } else {
          this.goToPreviousVideo();
        }
        this.startY = currentY; // Reset startY to avoid repeated triggers
      }
    },
     handleTouchEnd() {
    const videoListContainer = this.$refs.videoListContainer;
    const scrollTop = videoListContainer.scrollTop;
    const containerHeight = videoListContainer.clientHeight;
    const scrollHeight = videoListContainer.scrollHeight;

    // Check if near the bottom of the list for loading more videos
    if (scrollTop + containerHeight >= scrollHeight - 100 && !this.isLoadingMore) {
      this.isLoadingMore = true; // Set loading flag
      this.loadMoreVids().finally(() => {
        console.log('scroll load more vids');
        this.isLoadingMore = false; // Reset loading flag once done
      });
    }
  },
    // handleTouchEnd() {
    //   const threshold = window.innerHeight;
    //   if (this.startY - this.endY > threshold * 0.15) {
    //     // Swipe up, show next video
    //     this.goToNextVideo();
    //     // event.preventDefault();
    //     // event.stopPropagation();
    //     this.startY = 0;
    //     this.endY = 0;
    //   } else if (this.endY - this.startY > threshold * 0.15) {
    //     // Swipe down, show previous video
    //     this.goToPreviousVideo();
    //     // event.preventDefault();
    //     // event.stopPropagation()
    //     this.startY = 0;
    //     this.endY = 0;
    //   } else {
    //     this.togglePlay();
    //     this.startY = 0;
    //     this.endY = 0;
    //   }
    // },

   setCurrentVideoIndex() {
  if (!this.videos || this.videos.length === 0) {
    console.error("Videos array is empty or not loaded yet");
    return;
  }

  const index = parseInt(this.videoId, 10); // Ensure videoId is parsed as a number
  if (index >= 0 && index < this.videos.length) {
    this.currentVideoIndex = index; // Set to found index
  } else {
    this.currentVideoIndex = 0; // Default to the first video if not found or out of range
    console.warn("Failed to find video with ID:", this.videoId, "Defaulting to index 0.");
  }
},
 async updateVideoSource() {
    const videoPlayer = this.$refs.mainVideoPlayer;
    this.showPlaceholder = true; // Trigger the fade-in effect
    videoPlayer.src = this.currentVideo.url;
    videoPlayer.load();

    videoPlayer.onloadeddata = () => {
      videoPlayer.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    };
  },
  async goToPreviousVideo() {
    if (this.currentVideoIndex > 0) {
      await this.pauseCurrentVideo(); // Pause current video and wait for it to finish
      this.currentVideoIndex--; // Move to the previous video
          this.preloadNextVideos(this.currentVideoIndex); // Preload the next set of videos

      await this.updateVideoSource(); // Load and autoplay the previous video
    }
  },
  },
};
</script>

<style scoped>

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.short-video {
  opacity: 1;
}

.scroll-animation {
  transition: transform 0.3s ease-in-out;
}
.pd-button {
  background: none;
  border: none;
  border-radius: 50%;
  width: 50px; /* Adjust width to match other buttons */
  height: 50px; /* Adjust height to match other buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px 0;
}

.pd-icon {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits within the button */
}


.report-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 300px; /* Adjust width as needed */
}

.report-popup h3 {
  margin-top: 0;
}

.report-popup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.report-popup input,
.report-popup textarea {
  width: calc(100% - 20px);
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.send-report-button {
  background-color: #ff0077;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.send-report-button:hover {
  background-color: #ff4081;
}

.close-popup-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.flag-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
  transition: color 0.3s;
}

.flag-icon {
  font-size: 30px; /* Increase the size of the icon */
  color: #ccc; /* Default color */
  transition: color 0.3s;
}

.flag-button.clicked .flag-icon {
  color: #ff0000; /* Change to red when clicked */
}


.live-cam-widget {
  margin-top: 20px;
  text-align: center;
  min-height: 300px; /* Ensure sufficient space for the widget */
  border: 1px solid #ccc; /* Optional styling for visibility */
  background-color: #f8f8f8; /* Optional: Set a background to ensure visibility */
}

.video-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Takes full width */
  min-height: 100vh; /* Ensure it occupies a full screen height */
  background-color: #1d1f27; /* Dark background color */
  color: #ffffff; /* Text color */
  text-align: center;
  padding: 40px 20px; /* Padding for the content */
  margin: 0; /* No margin to align with other items */
  border-radius: 0; /* No border-radius */
  scroll-snap-align: start; /* Align correctly in scroll */
  z-index: 1000; /* Ensure it doesn't overlap other content */
}

.placeholder-icon {
  margin-bottom: 20px;
}

.placeholder-icon img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.placeholder-text h3 {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

.placeholder-text p {
  font-size: 1.5rem;
  margin: 10px 0;
}

.join-button {
  background-color: #ff0077;
  color: white;
  border: none;
  padding: 15px 30px; /* Increase padding for a larger button */
  font-size: 1.5rem; /* Increase the font size */
  border-radius: 8px; /* Adjust the border radius */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.join-button:hover {
  background-color: #ff4081;
}

.premium-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Optional: Add a background to distinguish it */
  z-index: 20; /* Ensure this has a higher z-index */
}

.new-image-button {
  position: absolute;
  top: 25px; /* Adjust to align with the fapshort2.png icon */
  right: 10px; /* Adjust as needed */
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%; /* Make it circular */
  padding: 0; /* Remove default padding */
 -webkit-tap-highlight-color: transparent; /* Remove tap highlight color on mobile */
}

.new-image-icon {
  position: absolute;
  top: -15px; /* Adjust to allow overflow */
  left: -15px; /* Adjust to allow overflow */
  width: 60px; /* Adjust size as needed */
  /* height: 80px;  */
  object-fit: cover; /* Ensure the image covers the button without distortion */
  z-index: 2; /* Ensure the image is above the video */
}

.new-circular-video {
  position: absolute;
  top: -5px;
  left: -9px;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the button without distortion */
  pointer-events: none; /* Ensure the video doesn't interfere with button clicks */
  z-index: 5; /* Make sure the video is below the image */
  clip-path: circle(50%); /* Restrict the video to a circular shape */
}

.new-image-button:hover .new-circular-video {
  opacity: 1; /* Fully opaque on hover */
}

/* Ensure focus and active outlines are removed */
.new-image-button:focus,
.new-image-button:active {
  outline: none;
  box-shadow: none;
}

.right-button.login-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px 0;
  font-size: 2em;
  transition: transform 0.3s;
}

.login-icon {

  opacity: 0.8;
  filter: brightness(0) invert(1);
  transition: filter 0.3s, opacity 0.3s;
}

.login-button:active .login-icon {
  animation: click-effecti 0.5s forwards;
}

@keyframes click-effecti {
  0% {
    transform: scale(1);
    filter: brightness(0) invert(1); /* Make it white */
  }
  50% {
    transform: scale(1.2);
    filter: invert(42%) sepia(90%) saturate(599%) hue-rotate(-55deg) brightness(95%) contrast(99%); /* Change to pink */
  }
  100% {
    transform: scale(1);
filter: invert(42%) sepia(90%) saturate(599%) hue-rotate(-55deg) brightness(95%) contrast(99%);  }
}

.video-wrapper {
  display: flex;
  flex-direction: column; /* Stack videos vertically */
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; /* Ensure each video takes full viewport height */
  scroll-snap-align: start; /* Snap to each video on scroll */
}

.shuffle-button {
  background-color: transparent;
  border: none;
  border-radius: 50%; /* Circular shape */
  width: 50px; /* Match size of channel-button */
  height: 50px; /* Match size of channel-button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px 0; /* Space between buttons */
  font-size: 2em;
  transition: transform 0.3s, background-color 0.3s, border 0.3s;
}

.shuffle-button .shuffle-icon {
  height: 100%;
  transition: filter 0.3s, opacity 0.3s;
  filter: brightness(0) invert(1); /* Make it white */
}

.shuffle-button.shuffleActive .shuffle-icon {
  filter: grayscale(0%) invert(42%) sepia(90%) saturate(599%) hue-rotate(-55deg)
    brightness(95%) contrast(99%); /* Change to pink */
  /* background-color: rgba(255, 20, 147, 0.7); */
  /* color: rgba(255, 20, 147, 0.7); */
}

.toast-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.3s;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 256px; /* Increase the size */
  height: 256px; /* Increase the size */
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  filter: brightness(0) invert(1); /* Make it white */
  z-index: 10;
  outline: none; /* Remove blue outline */
  box-shadow: none; /* Remove any box shadow */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight color */
}

.play-icon {
  width: 20%; /* Increase the size */
  height: 20%; /* Increase the size */
  opacity: 0.9; /* Make it less translucent */
  filter: brightness(0) invert(1); /* Make it white */
  transition: opacity 0.3s;
  justify-content: center;
  scale: 2;
  outline: none; /* Remove blue outline */
  box-shadow: none; /* Remove any box shadow */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight color */
}

.play-icon:hover {
  opacity: 1; /* Make it fully opaque on hover */
}

.mute-button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px; /* Adjusted width */
  height: 80px; /* Adjusted height */
}

.mute-icon {
  color: white;
  width: 60px; /* Adjust to fit within the button */
  height: 60px; /* Adjust to fit within the button */
  filter: brightness(0) invert(1); /* Start as white */
  transition: filter 0.3s;
}

.mute-button:active .mute-icon .play-icon .play-button {
  animation: click-effect 0.5s forwards; /* Trigger click animation */
}

.progress-bar {
  position: fixed; /* Change to fixed or absolute depending on your needs */
  bottom: 8%; /* Adjust as needed */
  left: 50%; /* Center the progress bar */
  transform: translateX(-50%);
  width: 90%; /* Adjust the width to fit the screen */
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  background: #fff; /* Change the background to white */
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  z-index: 20; /* Ensure it is above the video */
}

.progress-bar:hover {
  opacity: 1;
}

/* Customize the thumb of the input range */
.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #fff; /* Change the thumb to white */
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.progress-bar::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #fff; /* Change the thumb to white */
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.progress-bar::-ms-thumb {
  width: 15px;
  height: 15px;
  background: #fff; /* Change the thumb to white */
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

/* .shorts-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 10;
  margin: 0;
  padding: 0;
} */

.video-item {
  position: relative;
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.video-list-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow-y: scroll; /* Enable vertical scrolling */
  scroll-snap-type: y mandatory; /* Snap scrolling */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.video-list-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, Opera */
}
.shorts-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 10;
  overflow: hidden;
}
.likes-counter {
  color: white;
  font-size: 1em;
  text-align: center; /* Ensure it's centered */
}
.exit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  z-index: 20;
}

.nav-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
}
.nav-button.left {
  position: absolute;
  left: 10px;
}
.nav-button.right {
  position: absolute;
  right: 10px;
}
.short-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: contain; /* Ensure the video covers the viewport without distortion */
}

@media (max-width: 768px) {
  .short-video {
    object-fit: cover;
  }
}
.channel-video {
  border-radius: 50%; /* Ensure video is circular */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the button without distortion */
  transform: scale(1.2); /* Make the video larger */
  position: center;
}
.additional-buttons {
  position: absolute;
  top: 60%; /* Adjusted from 66% to 60% to move the buttons higher */
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}
.share-button:active .heart-icon,
.comment-button:active .heart-icon .play-icon .play-button {
  animation: click-effect 0.5s forwards; /* Trigger click animation */
}

@keyframes click-effect {
  0% {
    filter: brightness(0) invert(1); /* Start as white */
    transform: scale(1);
  }
  50% {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(1)
      contrast(1); /* Turn cyan */
    transform: scale(1.2);
  }
  100% {
    filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(180deg)
      brightness(1) contrast(1); /* Turn cyan */
    transform: scale(1); /* Return to normal size */
  }
}

.right-button {
  background-color: transparent;
  border: none; /* Remove border */
  border-radius: 50%; /* Circular shape */
  width: 50px; /* Match size of channel-button */
  height: 50px; /* Match size of channel-button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px 0; /* Space between buttons */
  font-size: 2em;
  transition: transform 0.3s;
}

.right-button:focus {
  outline: none; /* Ensure focus outline is removed */
}
.right-button.liked {
  color: red;
  transform: scale(1.2);
}
.video-title {
  display: flex;
  /* justify-content: space-around; */
  width: 100%;
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 2%;
  /* transform: translateX(-50%); */
  z-index: 20;
}

.animated-image {
  width: 45%; /* Adjust size as needed */
  height: auto; /* Adjust size to maintain aspect ratio */
  max-height: 100px; /* Ensure maximum height */
  object-fit: contain; /* Make sure the image fits within the specified area */
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.image1 {
  animation: pulse 2s infinite ease-in-out;
  mask-image: url("@/assets/fapshort2.png"); /* Ensure this points to the correct image */
  -webkit-mask-image: url("@/assets/fapshort2.png"); /* Support for Safari */
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.image2 {
  animation-name: shake; /* Use your desired animation */
  animation-delay: 3s; /* Start after 3 seconds */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

/* Define animations */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.burger-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  z-index: 20;
  transition: transform 0.5s; /* For spin animation */
}

.burger-menu.spin {
  transform: rotate(360deg); /* Spin effect */
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-list {
  background: #333;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
}

.menu-list button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  padding: 10px;
  margin: 5px 0;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.close-menu-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  z-index: 31; /* Make sure it's above the menu */
}

.menu-list button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.heart-button {
  color: grey; /* Start as grey */
}

.heart-button .heart-icon {
  /* width: 100%;
  height: 100%; */
  opacity: 0.8; /* Slightly translucent */

  filter: invert(70%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(1.8); /* Start as light grey */
  transition: filter 0.3s, opacity 0.3s;
}

.heart-button.liked .heart-icon {
  filter: grayscale(0%) invert(42%) sepia(90%) saturate(599%) hue-rotate(-55deg)
    brightness(95%) contrast(99%); /* Change to red */
  animation: heart-beat 1.5s;
  opacity: 1;
}
@keyframes heart-beat {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(1);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.2);
  }
}

.channel-button {
  background-color: transparent;
  border: 3px solid rgba(255, 20, 147, 0.7); /* Pink border */
  border-radius: 50%; /* Circular shape */
  padding: 5px; /* Adjust padding as needed */
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px; /* Space between buttons */
  outline: none; /* Remove blue outline */
  animation: pulse 2s infinite; /* Add pulse animation */
}

.black-outline {
  border-color: rgba(255, 230, 0, 0.7) !important; /* Ensure this overrides any other border settings */
  border: 3px solid rgba(255, 230, 0, 0.7);
  animation: pulse2 2s infinite;
}

/* Optional: Modify hover effect specifically for black-outline */
.black-outline:hover {
  box-shadow: 0 0 10px rgba(255, 230, 0, 0.7); /* Add a subtle black shadow on hover */
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 230, 0, 0.7); /* Pink shadow */
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 20, 147, 0); /* Transparent shadow */
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 20, 147, 0); /* Transparent shadow */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 20, 147, 0.7); /* Pink shadow */
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 20, 147, 0); /* Transparent shadow */
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 20, 147, 0); /* Transparent shadow */
  }
}

.channel-button img {
  border-radius: 50%; /* Ensure image is circular */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the button without distortion */
}

.additional-buttons {
  position: absolute;
  top: 60%; /* Adjust as needed */
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.right-button,
.channel-button,
.exit-button,
.nav-button,
.burger-menu {
  outline: none; /* Remove default outline */
}

.right-button:focus,
.channel-button:focus,
.exit-button:focus,
.nav-button:focus,
.burger-menu:focus,
.right-button:active,
.channel-button:active,
.exit-button:active,
.nav-button:active,
.burger-menu:active {
  outline: none; /* Ensure focus and active outlines are removed */
  box-shadow: none; /* Remove box shadow outline */
  -webkit-box-shadow: none; /* Remove WebKit box shadow outline */
}

.right-button,
.channel-button,
.exit-button,
.nav-button,
.burger-menu {
  outline: none; /* Remove default outline */
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /* Remove tap highlight color for mobile devices */
}

.right-button:focus-visible,
.channel-button:focus-visible,
.exit-button:focus-visible,
.nav-button:focus-visible,
.burger-menu:focus-visible {
  outline: none; /* Ensure focus-visible outlines are removed */
  box-shadow: none; /* Remove box shadow outline */
  -webkit-box-shadow: none; /* Remove WebKit box shadow outline */
}
</style>
